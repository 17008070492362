import { getImageBaseUrl } from './image';
import AudioPlayer from 'components/AudioPlayer';
import {
  addProtocolInURL,
  extractCompanyLogoNumber,
  formatPhoneNumber,
  extractBackgroundImageUrls,
} from './string';
import { CompanyEntity } from 'hooks/useProfile';
export const linkStyles = `style="
  color: #1F1F1F; 
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-decoration: none;
"`;

export const addressStyles = `style="
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FFF;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
"`;

export const buttonStyles = `style="
display: inline-flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
position: relative;
box-sizing: border-box;
-webkit-tap-highlight-color: transparent;
background-color: transparent;
cursor: pointer;
user-select: none;
vertical-align: middle;
appearance: none;
text-align: center;
font-size: 1.5rem;
color: rgba(0, 0, 0, 0.54);
outline: 0px;
border-width: 0px;
border-style: initial;
border-color: initial;
border-image: initial;
margin: 0px;
text-decoration: none;
flex: 0 0 auto;
padding: 2px;
border-radius: 50%;
overflow: visible;
transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
"`;

export const replaceCompanyName = (
  layout: string,
  companyName: string,
  companyURL: string
): string => {
  if (!companyName) {
    return layout.replace('__COMPANY_LOGO__', '');
  }
  const imgTag = `<img src="${companyName}" alt="Company Logo" style="pointer-events: none; max-width: 110px" />`;
  const companyTag = companyURL
    ? `<a id="preserve-click" href="${addProtocolInURL(
        companyURL
      )}" target="_blank" style="display: block">${imgTag}</a>`
    : imgTag;

  return layout.replace('__COMPANY_LOGO__', companyTag);
};

export const replaceFullName = (layout: string, fullName: string): string => {
  return layout.replace('__FULL_NAME__', fullName);
};

export const replaceQRCode = (layout: string, QRcode: string): string => {
  return layout?.replace(
    '__QR_CODE__',
    QRcode
      ? `<img
        src="https://api.qrserver.com/v1/create-qr-code/?size=230x230&data=${QRcode}"
        style="height: 180px; width: 180px; padding: 10px; background-color: white; margin-top: 100px;"
      />`
      : ''
  );
};

export const replaceRole = (layout: string, role: string): string => {
  return layout.replace('__ROLE__', role);
};

export const replaceDepartment = (layout: string, department: string): string => {
  return layout.replace('__DEPARTMENT__', department);
};

export const replacePronouns = (layout: string, pronouns: string): string => {
  return layout.replace('__PRONOUNS__', pronouns);
};

export const replaceNamePronounciation = (
  layout: string,
  namePronounciation: string
): string => {
  return layout.replace('___NAME_PRONUNCIATION___', namePronounciation);
};

export const replacePhone = (layout: string, phone: string): string => {
  return layout.replace(
    '__PHONE__',
    `<a id="preserve-click" href="tel:${phone}" ${linkStyles}>${formatPhoneNumber(
      phone
    )}</a>`
  );
};

export const replaceEmail = (layout: string, email: string): string => {
  return layout.replace(
    '__EMAIL__',
    `<a id="preserve-click" href="mailto:${email}" ${linkStyles}>${email}</a>`
  );
};

export const replaceProfilePicture = (
  layout: string,
  profilePicture: string
): string => {
  return profilePicture
    ? layout.replace(
        '__PROFILE_PICTURE__',
        `<img src="${profilePicture}" alt="User Image" />`
      )
    : layout.replace('__PROFILE_PICTURE__', '');
};

export const replaceProfilePicturewithBorder = (
  layout: string,
  profilePicture: string,
  CompanyTheme?: { headerTextColor: any } | undefined
): string => {
  return profilePicture
    ? layout.replace(
        '__PROFILE_PICTURE__',
        `<img src="${profilePicture}" alt="User Image" border = "4px solid ${CompanyTheme?.headerTextColor};" />`
      )
    : layout.replace('__PROFILE_PICTURE__', '');
};

export const replaceAddress = (
  layout: string,
  address: string,
  addressURL: string
): string => {
  return layout.replace(
    '__ADDRESS__',
    `<a ${addressStyles} href="${addressURL}">${address}</a>`
  );
};

export const formatPaperViewLayout = async ({
  paperViewLayout,
  companyName,
  companyURL = '',
  fullName,
  role,
  phone,
  email,
  profilePicture,
  address,
  addressURL = '',
  qrCodeURL,
  companyEntity,
  fullAddress = '',
}: {
  paperViewLayout: string;
  companyName: string;
  companyURL?: string;
  fullName: string;
  role: string;
  phone: string;
  email: string;
  profilePicture: string;
  address: string;
  addressURL?: string;
  qrCodeURL: string;
  companyEntity?: CompanyEntity | null;
  fullAddress: string;
}) => {
  const companyLogoNumber = extractCompanyLogoNumber(paperViewLayout);

  const { companyLogos = [] } = companyEntity ?? {};

  const formattedWithCompanyLogo = paperViewLayout.replace(
    `__COMPANY_LOGO_${companyLogoNumber}__`,
    companyLogos
      ? `<img src=${getImageBaseUrl({
          imageUrl: companyLogos[companyLogoNumber - 1]?.field_logo_img,
        })}  />`
      : `<img src=${companyName} style="width:100%" />`
  );

  const formattedCompanyNameLayout = replaceCompanyName(
    formattedWithCompanyLogo,
    companyName,
    companyURL
  );
  const formattedFullNameLayout = replaceFullName(
    formattedCompanyNameLayout,
    fullName
  );
  const formattedRoleLayout = replaceRole(formattedFullNameLayout, role);
  const formattedPhoneLayout = replacePhone(formattedRoleLayout, phone);
  const formattedEmailLayout = replaceEmail(formattedPhoneLayout, email);
  const formattedProfilePictureLayout = replaceProfilePicture(
    formattedEmailLayout,
    profilePicture
  );

  const fullAddressLayoutTrimmed = fullAddress.trim();
  const fullAddressLayoutWithOutComma = fullAddressLayoutTrimmed.endsWith(',')
    ? fullAddressLayoutTrimmed.slice(0, -1)
    : fullAddressLayoutTrimmed;
  const trimmedAddress =
    fullAddressLayoutWithOutComma?.split('\n')?.length >= 3
      ? fullAddressLayoutWithOutComma.split('\n').slice(0, 2).join('\n')
      : fullAddressLayoutWithOutComma;

  const formattedAddressLayout = replaceAddress(
    formattedProfilePictureLayout,
    trimmedAddress,
    addressURL
  );

  const formattedFinal = replaceQRCode(formattedAddressLayout, qrCodeURL);
  return formattedFinal;
};

export const formatBackFlipLayout = async ({
  backFlipLayout,
  profile,
}: {
  backFlipLayout: string;
  profile: any;
}) => {
  let htmlStr = backFlipLayout;
  htmlStr = htmlStr?.replace(
    '__QR_CODE__',
    profile?.metatag?.canonical_url
      ? `<img src="https://api.qrserver.com/v1/create-qr-code/?size=230x230&data=${profile?.metatag?.canonical_url}" style="display: block; height: 230px; width: 230px; padding: 10px;" />`
      : ''
  );
  return htmlStr;
};

export const formatFullProfileHeaderLayout = async ({
  fullProfileHeaderLayout,
  companyName,
  companyURL = '',
  fullName,
  role,
  phone,
  email,
  profilePicture,
  address,
  addressURL = '',
  qrCodeURL,
  companyEntity,
  fullAddress = '',
  pronouns,
  namePronuciationUrl,
  headerImageMobile,
  headerImageDesktop,
  headerBackgroundColor,
  department,
}: {
  fullProfileHeaderLayout: string;
  companyName: string;
  companyURL?: string;
  fullName: string;
  role: string;
  phone: string;
  email: string;
  profilePicture: string;
  address: string;
  addressURL?: string;
  qrCodeURL: string;
  companyEntity: CompanyEntity;
  fullAddress: string;
  pronouns: string;
  namePronuciationUrl: string;
  headerImageMobile: string;
  headerImageDesktop: string;
  headerBackgroundColor: string;
  department: string;
}) => {
  const companyLogoNumber = extractCompanyLogoNumber(fullProfileHeaderLayout);
  const { mobileUrl, desktopUrl } = extractBackgroundImageUrls(
    fullProfileHeaderLayout
  );
  const { companyLogos, theme } = companyEntity;
  const backgroundImageUrl = window.innerWidth < 576 ? mobileUrl : desktopUrl;

  const updatedFullProfileHeaderLayout = fullProfileHeaderLayout
    .replace(/__HEADER_IMAGE_MOBILE__/g, backgroundImageUrl || headerImageMobile)
    .replace(/__HEADER_IMAGE_DESKTOP__/g, backgroundImageUrl || headerImageDesktop)
    .replace(
      /__COLOR_BACKGROUND__/g,
      headerBackgroundColor || theme?.headerBgColor || '#8544d8' // Fallback to a default color
    );
  const formattedWithCompanyLogo = updatedFullProfileHeaderLayout.replace(
    `__COMPANY_LOGO_${companyLogoNumber}__`,
    companyLogos
      ? `<img src=${getImageBaseUrl({
          imageUrl: companyLogos[companyLogoNumber - 1]?.field_logo_img,
        })}  />`
      : `<img src=${companyName} style="width:100%" />`
  );

  const formattedCompanyNameLayout = replaceCompanyName(
    formattedWithCompanyLogo,
    companyName,
    companyURL
  );
  const formattedFullNameLayout = `<span style="color: ${theme?.headerTextColor};">${replaceFullName(
    formattedCompanyNameLayout,
    fullName
  )}</span>`;

  const formattedRoleLayout = replaceRole(formattedFullNameLayout, role);

  const formattedPronounsLayout = replacePronouns(formattedRoleLayout, pronouns);

  const formattedPhoneLayout = replacePhone(formattedPronounsLayout, phone);
  const formattedEmailLayout = replaceEmail(formattedPhoneLayout, email);

  const namePronunciationContent = namePronuciationUrl
    ? `<span class="makeStyles-iconName-3669">
        <button 
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium"
          id="audio-toggle-button" 
          type="button"
          ${buttonStyles}>
          <svg height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#fff">
             <path
        d="M14 3.65332V5.71332C16.89 6.57332 19 9.25332 19 12.4233C19 15.5933 16.89 18.2633 14 19.1233V21.1933C18 20.2833 21 16.7033 21 12.4233C21 8.14332 18 4.56332 14 3.65332ZM16.5 12.4233C16.5 10.6533 15.5 9.13332 14 8.39332V16.4233C15.5 15.7133 16.5 14.1833 16.5 12.4233ZM3 9.42332V15.4233H7L12 20.4233V4.42332L7 9.42332H3Z"
        fill=${theme?.headerTextColor}
      />
          </svg>
        </button>
        <audio controls style="display: none;" id="audio-player">
          <source src="${namePronuciationUrl}" type="audio/mp3">Your browser does not support the audio element.</audio>
      </span>`
    : '';

  const formattedNamePronounciationLayout = formattedEmailLayout.replace(
    /__NAME_PRONUNCIATION__/g,
    namePronunciationContent
  );
  const toggleButton = document.getElementById('audio-toggle-button');
  const audioPlayer = document.getElementById('audio-player') as HTMLAudioElement;

  if (toggleButton && audioPlayer) {
    toggleButton.addEventListener('click', function () {
      // Handle play/pause properly
      if (audioPlayer.paused) {
        audioPlayer
          .play()
          .then(() => {
            toggleButton.innerHTML = `<svg height="24px" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill=${theme?.headerTextColor}>
             <path
        d="M14 3.65332V5.71332C16.89 6.57332 19 9.25332 19 12.4233C19 15.5933 16.89 18.2633 14 19.1233V21.1933C18 20.2833 21 16.7033 21 12.4233C21 8.14332 18 4.56332 14 3.65332ZM16.5 12.4233C16.5 10.6533 15.5 9.13332 14 8.39332V16.4233C15.5 15.7133 16.5 14.1833 16.5 12.4233ZM3 9.42332V15.4233H7L12 20.4233V4.42332L7 9.42332H3Z"
        fill=${theme?.headerTextColor}</svg>`;
          })
          .catch((error) => {
            console.error('Error playing audio:', error);
          });
      } else {
        audioPlayer.pause();
        toggleButton.innerHTML = `<svg height="24px" width="24px" viewBox="0 0 24 24" fill=${theme?.headerTextColor}>
         <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"></path>
            </svg>`;
      }
    });
  }

  const formattedProfilePictureLayout = replaceProfilePicturewithBorder(
    formattedNamePronounciationLayout,
    profilePicture
  );

  const fullAddressLayoutTrimmed = fullAddress.trim();
  const fullAddressLayoutWithOutComma = fullAddressLayoutTrimmed.endsWith(',')
    ? fullAddressLayoutTrimmed.slice(0, -1)
    : fullAddressLayoutTrimmed;

  const trimmedAddress =
    fullAddressLayoutWithOutComma?.split('\n')?.length >= 3
      ? fullAddressLayoutWithOutComma.split('\n').slice(0, 2).join('\n')
      : fullAddressLayoutWithOutComma;

  const formattedAddressLayout = replaceAddress(
    formattedProfilePictureLayout,
    trimmedAddress,
    addressURL
  );
  const formattedDepartmentLayout = replaceDepartment(
    formattedAddressLayout,
    department
  );
  const formattedFinal = replaceQRCode(formattedDepartmentLayout, qrCodeURL);
  return formattedFinal;
};
